.App {
  display: flex;
  flex-direction: column;
  height: 100vh;

  header {
    padding: 1rem;

    h1 {
      margin-top: 0;
    }
  }
}

a {
  color: inherit;

  transition: text-shadow 0.2s ease-in-out;
  text-shadow: rgba(0, 0, 0, 0) 0 2px 2px;

  &:hover {
    text-shadow: rgba(0, 0, 0, 0.2) 0 2px 2px;
  }
}

nav {
  display: flex;
  gap: 1rem;
}

.dashboard {
  padding: 1rem;
}

input {
  padding: 0.5em 0.75em;

  border: none;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.2s ease-in-out;

  &:focus {
    outline: none;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.4);
  }
}

button,
.button {
  padding: 0.5em 0.75em;

  border: none;
  font-size: 0.75em;
  text-decoration: none;

  background: none;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.4);
  }

  &:active {
    outline: none;
    box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.4);
  }
}
