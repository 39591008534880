%modal {
  overflow: hidden;
  position: fixed;
  z-index: 999;
  background: rgba($color: #000000, $alpha: 0.8);

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  & > * {
    box-shadow: 0px 0px 1rem 0px black;
  }
}

@mixin shadow($size: 1) {
  box-shadow: 0px -5px (3px * $size) 0px rgba(170, 170, 170, 0.2), 0px 15px (20px * $size) 0px rgba(170, 170, 170, 0.7),
    0px 5px (5px * $size) 0px rgba(119, 119, 119, 0.3);
}

@mixin scroll-shadow-horizontal($size: 1rem, $background: white) {
  background-image: linear-gradient(to right, $background, $background),
    linear-gradient(to right, $background, $background),
    radial-gradient(ellipse closest-side, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%),
    radial-gradient(ellipse closest-side, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);

  background-position: left center, right center, -($size / 2) center, right (-($size / 2)) center;
  background-repeat: no-repeat;
  background-color: $background;
  background-size: $size 100%, $size 100%, $size 100%, $size 100%;
  background-attachment: local, local, scroll, scroll;
}

@mixin scroll-shadow-vertical($size: 1rem, $background: white) {
  background: linear-gradient($background 30%, rgba(255, 255, 255, 0)) center top,
    linear-gradient(rgba(255, 255, 255, 0), $background 70%) center bottom,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center top,
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center bottom;

  background-repeat: no-repeat;
  background-size: 100% $size * 1.5, 100% $size * 1.5, 100% $size, 100% $size;
  background-attachment: local, local, scroll, scroll;
}
