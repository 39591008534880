@import "_helpers.scss";

.scanner {
  &.modal {
    @extend %modal;

    video {
      display: inline-block;
      max-width: 100%;
    }

    canvas {
      display: none;
    }
  }

  a {
    background: white;
  }
}
