@use "sass:color";
@use "sass:math";

.book {
  flex-basis: 9rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &.finished {
    .cover {
      &:after {
        content: "\2713";
        text-decoration: none;
        position: absolute;
        display: block;
        font-size: 1.5rem;
        color: green;
        background: white;
        height: 1.25em;
        width: 1.25em;
        text-align: center;
        line-height: 1.25em;
        border-radius: 50%;
        bottom: 0.25em;
        right: 0.25em;
        box-shadow: 0px -5px 3px 0px rgba(170, 170, 170, 0.2), 0px 15px 20px 0px rgba(170, 170, 170, 0.7),
          0px 5px 5px 0px rgba(119, 119, 119, 0.3);
      }
    }
  }

  &::after {
    box-shadow: 0px -5px 3px 0px rgba(170, 170, 170, 0.2), 0px 15px 20px 0px rgba(170, 170, 170, 0.7),
      0px 5px 5px 0px rgba(119, 119, 119, 0.3);
    content: " ";
    height: 1em;
    width: 100%;
  }

  .cover {
    & > * {
      box-shadow: 0px -5px 20px 2px rgba(0, 0, 0, 0.3);
      width: 8rem;
      margin: auto;
    }
    position: relative;

    img {
      max-width: 100%;
      height: auto;
      display: block;
    }

    .default {
      text-align: center;
      display: flex;
      flex-direction: column;
      max-width: 100%;
      background: wheat;
      aspect-ratio: 1 / 1.5;
      align-items: center;
      justify-content: space-around;
      line-height: 1.25em;
      box-sizing: border-box;
      padding: 0.5em;

      .authors {
        text-transform: uppercase;
      }
    }
  }
}
