@import "../_helpers.scss";

.details {
  & > * {
    background: white;
    padding: 1rem;
    max-width: 95vw;
    width: 640px;
    box-sizing: border-box;
  }

  .info-grid {
    display: flex;
    flex-direction: column;
    gap: 0.75em;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    a {
      transition: all 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
