.form {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75em;

  *:has(label) {
    display: flex;
    gap: 0.5em;
    flex-direction: column;

    label {
      min-width: min-content;
      max-width: max-content;
    }
    input {
      flex: 1;
    }
  }

  .qr-code,
  .qr-scanner {
    background: "white";
    padding: 1rem;
  }
}

hr {
  border: none;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0) 75%);
  height: 0.25rem;
  width: 100%;
}
